import React, { lazy, Suspense } from 'react';
import './App.css';
import './i18n';
import Navbar from './components/Navbar';
import Footer from './components/Footer/Footer';
import BackToTopButton from './components/BackToTop/backToTop';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages';
import About from './pages/About';
import Apartments from './pages/apartments';

/* To increase the speed of the app, we lazy load other pages */
const Contact = lazy(() => import('./pages/contact'));
const Activities = lazy(() => import('./pages/activities'));
const Destinations = lazy(() => import('./pages/destinations'));

function App() {
  return (
    <Router>
      <Navbar position="fixed" />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/apartments" element={<Apartments />} />
          <Route path="/about" element={<About />} />
          <Route path="/destinations" element={<Destinations />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Suspense>
      <BackToTopButton />
      <Footer position="fixed" />
    </Router>
  );
}

export default App;
