import React from 'react';
import './PhotoViewerSection.scss';

import image1 from '../../img/ladugård_livingroom1.jpg';
import image2 from '../../img/ladugård_livingroom2.jpg';
import image3 from '../../img/ladugård_hallway1.jpg';
import image4 from '../../img/ladugård_hallway2.jpg';
import image5 from '../../img/ladugård_kitchen.livingroom.jpg';
import image11 from '../../img/ladu_02.JPG';
import image12 from '../../img/ladu_03.jpg';
import image13 from '../../img/ladu_04.jpg';
import image14 from '../../img/ladu_05.jpg';
import image15 from '../../img/L_09.jpg';

const PhotoViewerSectionL = () => {
  const images = [
    image1,
    image2,
    image5,
    image4,
    image3,
    image13,
    image14,
    image12,
    image15,
    image11,
  ];

  return (
    <div className="photo-viewer-section">
      {images.map((image, index) => (
        <div key={index} className="image-wrapper">
          <img src={image} alt={`This is ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};

export default PhotoViewerSectionL;
