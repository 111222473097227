import React from 'react';
import '../styles/rooms.scss';

import PhotoViewerSectionR from '../components/photoViewer/photoviewer_rannet';
import PhotoViewerSectionL from '../components/photoViewer/photoviewer_ladu';
import BookRoomButton from '../components/BookRoom/BookARoom';

import { useTranslation } from "react-i18next";

const Apartments = () => {

const { t } = useTranslation();

return (
  <div className="rooms">
    <section className="description">
      <h2>{t('Book your stay with us below')}</h2>
    </section>

    <section className="section_rannet">
      <div className="text">
        <h2>Rännet</h2>
        <d> {t('The apartment is located in the former Economy Building.')}</d>
        <br/>
        <d>
          {t('The apartment is 100 square meters and is built on two floors with an open floor plan up to the roof. It consists of three large bedrooms with 2+2+6 beds, a sleeping loft with another 5 bed mattresses, 2 toilets with shower, and a large kitchen and living room. The kitchen is fully equipped for 13 people.')}
        </d>
		<br/>
        <d>
          {t('You have free access to our garden with a lovely lawn and a large seating area with a barbecue. There are many plants in the garden. At Rovor och Rum there are three kayaks and six bicycles that you can rent for a small cost. Access to free Wi-Fi. Bed linen and towels are always included.')}
        </d>
      </div>

      <PhotoViewerSectionR />
      <BookRoomButton />
    </section>

    <section className="carousel_ladugarden">
      <div className="text">
        <h2>Ladugården</h2>
        <d>
		{t('The apartment is carefully renovated with preserved details and beams from the old barn with a modern and elegant interior. The apartment is 100 m2 and has a total of 12 beds spread over 4 bedrooms, all with their own shower and toilet. The kitchen and living room have a large open floor plan with a wood burning stove. The kitchen is fully equipped for 13 people. Wi-Fi is available. Patio with barbecue in a genuine farm setting. At Rovor och Rum there are three kayaks and six bicycles that you can rent at a small cost.')}
        </d>
		<br />
        <d> {t('Access to free Wifi. Bed linen and towels are always included.')}</d>
      </div>

      <PhotoViewerSectionL />
      <BookRoomButton />
    </section>
  </div>
);
};

export default Apartments;