import React from 'react';
import './PhotoViewerSection.scss';

import image1 from '../../img/rännet.livingroom.jpg';
import image2 from '../../img/rännet.livingroomkitchen.jpg';
import image4 from '../../img/rännet.theblueroom.jpg';
import image6 from '../../img/rännet.upstairshall.jpg';
import image7 from '../../img/rännet.kitchen.jpg';
import image8 from '../../img/rännet.växasäng_01.jpg';
import image9 from '../../img/rännet.sovloft.jpg';
import image10 from '../../img/r_02.jpg';
import image11 from '../../img/r_03.JPG';


const PhotoViewerSectionR = () => {
  const images = [
    image1,
    image2,
    image7,
    image6,
    image4,
    image8,
    image11,
    image9,
    image10,
  ];

  return (
    <div className="photo-viewer-section">
      {images.map((image, index) => (
        <div key={index} className="image-wrapper">
          <img src={image} alt={`This is ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};

export default PhotoViewerSectionR;
