import React from 'react';
import './bookroom.scss';
import { useTranslation } from "react-i18next";

function BookRoomButton() {
  const url = 'https://a4cb7cceed3c81ba.sirvoy.me/';

  const handleClick = () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const { t } = useTranslation();

  return (
    <div className='booking-button'>
      <button onClick={handleClick}>{t('Book Apartment')}</button>
    </div>
  );
}

export default BookRoomButton;
