import React from 'react';
import '../styles/about.scss';
import { useTranslation } from "react-i18next";

import Image1 from '../img/OS_01.JPG';
import Image2 from '../img/OS_02.jpg';
import Image3 from '../img/OS_03.JPG';
import Image4 from '../img/OS_04.JPG';

const About = () => {

const { t } = useTranslation();

return (
  <div className="about">
    <section className="welcome">
      <div className="text-container">
        <h2>{t('Vacation On A Farm!')}</h2>
        <p>
          {t('At Rovor & Rum you live in a rural environment in the middle of beautiful Toftenäs nature reserve, with proximity to everything that Tjörn has to offer.')}<br />
          {t('You are surrounded by lush greenery and pristine beaches. It is the perfect retreat for those who want to escape the hustle and bustle of the city.')}{" "}
          <br />
          {t('The farm is our family farm that has been farmed for six generations, dating back to the 18th century.')} <br />
          {t('Here on the farm, people lived on a small-scale farm with cows, pigs, horses, sheep and chickens. It gave the opportunity for self-catering.')}<br />
          {t('Living and working in the same place as our ancestors gives us great joy and inspiration.')} <br />
          {t('Today we focus on tourism and sheep farming.')}<br />
          {t('The economy building has been converted into two large apartments Rännet and Ladugården.')}<br />
          {t('In the past, grain and hay were stored in the Apartment Rännet, there was also a small mill where flour was ground as feed for the animals.')}<br />
          {t('The animals were housed in the Ladugården apartment.')}<br />
          {t('Today, the apartments are carefully renovated with all the amenities you need for a nice stay.')}
        </p>
      </div>

      <div className="carousel-container">
        <div className="card">
          <img src={Image1} alt="first_image" />
        </div>
        <div className="card">
          <img src={Image4} alt="second_image" />
        </div>
        <div className="card">
          <img src={Image3} alt="third_image" />
        </div>
        <div className="card">
          <img src={Image2} alt="fourth_image" />
        </div>
      </div>
    </section>
  </div>
);
};

export default About;
